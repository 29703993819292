import _parser from "./lib/parser";
import _compiler from "./lib/compiler";
var exports = {};
var parser = _parser;
var compiler = _compiler;
exports = {
  parse: function (input) {
    var nodes = parser.parse(input.toString());
    return compiler.compile(nodes);
  }
};
export default exports;
export const parse = exports.parse;